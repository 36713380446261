import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Img from 'gatsby-image'

class InternationalRelease extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="International Release"
          bodyClass="body--light"
          description="The Edge is available to purchase in countries across Latin America &amp; The Caribbean, Europe and more."
        />
        <article>
          <div className="c-masthead">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">International <br/>Release</h1>
                </div>
              </div>
            </div>
            <div className="c-masthead__image-container">
              <Img
                fluid={
                  this.props.data.title.childImageSharp.fluid
                }
                alt=""
                className="c-masthead__image"
              />
            </div>
          </div>
          <div className="c-content c-content--align-left">
            <div className="container">
              <div className="row">
                <div className="c-content__content-container">
                  <div className="cms-content">
                    <p><strong>BUY YOUR INTERNATIONAL COPY</strong></p>
                    <p><a className="c-btn c-btn--primary-fill " href="https://itunes.apple.com/gb/movie/the-edge/id1461503437?mt=6&ign-mpt=uo%3D4" rel="noopener noreferrer" target="_blank">Buy Now</a></p>
                    <hr/>
                    <p>The Edge is available to purchase in the following countries:</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-screening-listing">
            <div className="container">
              <div className="row">
                <div className="c-screening-listing__container">
                  <div className="c-screening-listing__group">
                    <h2 className="c-screening-listing__title">Latin America &amp; The Caribbean</h2>
                    <div className="c-screening-listing__table cms-content">
                      <ul>
                        <li>Anguilla</li>
                        <li>Antigua &amp; Barbuda</li>
                        <li>Bahamas</li>
                        <li>Belize</li>
                        <li>Bermuda</li>
                        <li>British Virgin Islands</li>
                        <li>Cayman Islands</li>
                        <li>Dominica</li>
                        <li>Grenada</li>
                        <li>St Kitts &amp; Nevis</li>
                      </ul>
                      <p><br></br></p>
                    </div>
                  </div>
                  <div className="c-screening-listing__group">
                    <h2 className="c-screening-listing__title">North America</h2>
                    <div className="c-screening-listing__table cms-content">
                      <ul>
                        <li>United States</li>
                        <li>Canada</li>
                      </ul>
                    </div>
                  </div>
                  <div className="c-screening-listing__group">
                    <h2 className="c-screening-listing__title">Europe</h2>
                    <div className="c-screening-listing__table cms-content">
                      <ul>
                        <li>Belarus</li>
                        <li>Belgium</li>
                        <li>Bulgaria</li>
                        <li>Cyprus</li>
                        <li>Czech rep.</li>
                        <li>Denmark</li>
                        <li>Estonia</li>
                        <li>Finland</li>
                        <li>Greece</li>
                        <li>Hungary</li>
                        <li>Latvia</li>
                        <li>Lithuania</li>
                        <li>Luxembourg</li>
                        <li>Malta</li>
                        <li>Moldova</li>
                        <li>Norway</li>
                        <li>Poland</li>
                        <li>Portugal</li>
                        <li>Slovakia</li>
                        <li>Slovenia</li>
                        <li>Sweden</li>
                        <li>Switzerland</li>
                      </ul>
                    </div>
                  </div>
                  <div className="c-screening-listing__group">
                    <h2 className="c-screening-listing__title">Asia Pacific</h2>
                    <div className="c-screening-listing__table cms-content">
                      <ul>
                        <li>Australia</li>
                        <li>Brunei</li>
                        <li>Cambodia</li>
                        <li>Federated States of Micronesia</li>
                        <li>Fiji</li>
                        <li>Laos</li>
                        <li>Malaysia</li>
                        <li>Mongolia</li>
                        <li>Philippines</li>
                        <li>Sri Lanka</li>
                        <li>Tajikistan</li>
                        <li>Turkmenistan</li>
                        <li>Vietnam</li>
                      </ul>
                    </div>
                  </div>
                  <div className="c-screening-listing__group">
                    <h2 className="c-screening-listing__title">Africa, Middle East &amp; India</h2>
                    <div className="c-screening-listing__table cms-content">
                      <ul>
                        <li>Armenia</li>
                        <li>Azerbaijan</li>
                        <li>Botswana</li>
                        <li>Cape Verde</li>
                        <li>Gambia</li>
                        <li>Guinea-Bissau</li>
                        <li>Israel</li>
                        <li>Mauritius</li>
                        <li>Mozambique</li>
                        <li>Namibia</li>
                        <li>Niger</li>
                        <li>Swaziland</li>
                        <li>Uganda</li>
                        <li>Zimbabwe</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-content c-content--align-left">
            <div className="container">
              <div className="row">
                <div className="c-content__content-container">
                  <div className="cms-content">
                    <p><strong>BUY YOUR INTERNATIONAL COPY</strong></p>
                    <p><a className="c-btn c-btn--primary-fill " href="https://itunes.apple.com/gb/movie/the-edge/id1461503437?mt=6&ign-mpt=uo%3D4" rel="noopener noreferrer" target="_blank">Buy Now</a></p>
                    <hr/>
                    <p>Please note that while we have released The Edge in many global regions, it is currently still not available to watch in all parts of the world. If your country is not listed above, we appreciate your patience, please know that we are working very hard to make this possible and will announce more details as soon as we can! Please contact <a href="mailto:info@theedgefilm.co.uk">info@theedgefilm.co.uk</a> for any further assistance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    title: file(relativePath: { eq: "internationalrelease/title.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default InternationalRelease
